import React from "react"

const ProjectCard = ({ project }) => {
    return (
        <a href={project.url}>
            <div className="projects__main__div">
                {/* <h3>{project.name}</h3> */}
                <img src={project.img.src} alt={`${project.img.alt}`} />
            </div>
        </a>
    )
}

export default ProjectCard

import React from "react"

const TypingCover = ({ text }) => {
    return (
        <div
            className="typingcover"
            style={{
                animationTimingFunction: `steps(${text.length})`,
            }}
        ></div>
    )
}

export default TypingCover

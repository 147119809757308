import React from "react"
import { useParallax } from "react-scroll-parallax"
import Layout from "./Layout"
import PageHeader from "./PageHeader"
import ProjectCard from "./ProjectCard"

import { projects } from "../util/projects"

const MyProjects = () => {
    const { ref } = useParallax({ opacity: [0, 2.3], speed: 10 })

    return (
        <Layout name="projects">
            <PageHeader heading="Projects" />
            <main className="projects__main" ref={ref}>
                {projects.map((project) => (
                    <ProjectCard project={project} key={project.name} />
                ))}
            </main>
        </Layout>
    )
}

export default MyProjects

import React from "react"

const Layout = ({ name, children }) => {
    return (
        <section className="container" id={name}>
            {children}
        </section>
    )
}

export default Layout

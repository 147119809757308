import React, { useRef } from "react"
import emailjs from "@emailjs/browser"

const SendEmail = () => {
    const form = useRef()
    const sendMail = (e) => {
        e.preventDefault()

        emailjs
            .sendForm(
                "contact_service",
                "contact_form",
                form.current,
                "BseAX0CZEdw9Y6DI9"
            )
            .then(
                (result) => {
                    console.log(result.text)
                },
                (error) => {
                    console.log(error.text)
                }
            )
    }

    return (
        <>
            <h2>Or send me an email</h2>
            <form className="form" ref={form} onSubmit={sendMail}>
                <label htmlFor="user_name">Your name</label>
                <input type="text" id="user_name" name="user_name" />
                <label htmlFor="user_email">Your email address</label>
                <input type="email" id="user_email" name="user_email" />
                <label htmlFor="message">Message</label>
                <textarea id="message" name="message"></textarea>
                <button>Send</button>
            </form>
        </>
    )
}

export default SendEmail

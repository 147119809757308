import React from "react"
import { useParallax } from "react-scroll-parallax"
import ContactButton from "./ContactButton"
import Layout from "./Layout"
import PageHeader from "./PageHeader"
import SendEmail from "./SendEmail"

const Contact = () => {
    const { ref } = useParallax({ opacity: [0, 2.3], speed: 10 })
    return (
        <Layout name="contact">
            <PageHeader heading="Contact" />
            <main className="contact__main" ref={ref}>
                <ContactButton
                    brand="github"
                    link="https://github.com/avi-mukesh"
                />
                <ContactButton
                    brand="linkedin"
                    link="https://www.linkedin.com/in/avi-mukesh/"
                />
                <ContactButton
                    brand="instagram"
                    link="https://www.instagram.com/avimu_photo/"
                />
            </main>
            <footer>
                <SendEmail/>
            </footer>
        </Layout>
    )
}

export default Contact

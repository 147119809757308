import Contact from "./components/Contact"
import Home from "./components/Home"
import MyProjects from "./components/MyProjects"
// import Navbar from "./components/Navbar"
import { useFontAwesome } from "./hooks/useFontAwesome"

function App() {
    useFontAwesome()
    return (
        <>
            {/* <Navbar /> */}
            <Home />
            <MyProjects />
            <Contact />
        </>
    )
}

export default App

import React from "react"
import { ParallaxBanner } from "react-scroll-parallax"
import Header from "./Header"
import Layout from "./Layout"

const Home = () => {
    const background = {
        shouldAlwaysCompleteAnimation: true,
        children: <Layout name="home" />,
    }

    const headline = {
        translateY: [0, 80],
        scale: [0.3, 1.2, "easeOutCubic"],
        expanded: false,
        children: <Header title="Avi" />,
    }

    return <ParallaxBanner layers={[background, headline]} className="full" />
}

export default Home

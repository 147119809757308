class ProjectImage {
    constructor(src, alt) {
        this.src = src
        this.alt = alt
    }
}

const projectImageAlts = ["University of Warwick Rubik's Cube Society logo", "Note taking app logo, a quill.", "Photography portfolio logo, a camera icon.", "Tutoring website logo, any topic, any level."]

function importAll(r) {
    let images = {}
    r.keys().forEach((item) => {
        images[item.replace("./", "")] = r(item)
    })
    return images
}
const projectImagePaths = importAll(
    require.context("../images", false, /\.(webp)$/)
)

export const projectImages = new Array(projectImageAlts.length)
    .fill(0)
    .map(
        (_, i) =>
            new ProjectImage(
                projectImagePaths[`${i}.webp`],
                projectImageAlts[i]
            )
    )

import { projectImages } from "./images"

class Project {
    constructor(name, description, img, url) {
        this.name = name
        this.description = description
        this.img = img
        this.url = url
    }
}

const rubikWebsite = new Project(
    "Warwick Rubik's Cube Society Website",
    "Website for the University of Warwick's Rubik's Cube Society",
    projectImages[0],
    "https://warwickrubik.web.app"
)
const notesApp = new Project(
    "Quill",
    "Note-taking web application",
    projectImages[1],
    "https://note.avimukesh.com"
)

const photoPortfolio = new Project(
    "Photography Portfolio",
    "Portfolio showcasing my photography work",
    projectImages[2],
    "https://photo.avimukesh.com"
)
const tutoringWebsite = new Project(
    "Avi Tutoring",
    "Website advertising my tutoring services",
    projectImages[3],
    "https://tutor.avimukesh.com"
)

export const projects = [rubikWebsite, photoPortfolio, notesApp, tutoringWebsite]

import React, { useEffect, useState } from "react"
import TypingCover from "./TypingCover"

const timePerSubtitle = 3000

const Header = () => {
    const subtitles = [
        "Web developer",
        // "Mathematician",
        "Speedcuber   ",
        "Photographer ",
        "Maths Tutor  ",
    ]
    const [subtitleIndex, setSubtitleIndex] = useState(0)

    const loadSubtitles = () => {
        for (let i = 0; i < subtitles.length; i++) {
            setTimeout(() => setSubtitleIndex(i), i * timePerSubtitle)
        }
    }

    useEffect(() => {
        loadSubtitles()
        const interval = setInterval(
            loadSubtitles,
            timePerSubtitle * subtitles.length
        )

        return () => clearInterval(interval)
    }, [])

    return (
        <header className="header">
            <h1 className="header__heading">AVI</h1>
            <h2 className="header__subtitle">
                <TypingCover text={subtitles[subtitleIndex]} />
                {subtitles[subtitleIndex]}
            </h2>
        </header>
    )
}

export default Header

import React from "react"
import { useParallax } from "react-scroll-parallax"

const PageHeader = ({ heading }) => {
    const { ref } = useParallax({ translateX: [-70, 0], opacity: [0, 2] })
    return (
        <header className="page-header">
            <h1 ref={ref}>{heading}</h1>
        </header>
    )
}

export default PageHeader
